/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IHoursChart,
  IStatistics,
  ISurveyWebChat,
  IUserList,
} from '../../../models/statistics/statistics';

interface IUserStatisticsSlice {
  agentInteractionStatistics: IStatistics[];
  userList: IUserList[];
  dataHoursChart: IHoursChart[];
  surveyWebChat: ISurveyWebChat;
  isloading: boolean;
  error: string | null;
}

const initialState: IUserStatisticsSlice = {
  agentInteractionStatistics: [],
  userList: [],
  surveyWebChat: {} as ISurveyWebChat,
  dataHoursChart: [],
  isloading: false,
  error: null,
};

export const userStatisticsStore = createSlice({
  name: 'userStatisticsState',
  initialState,
  reducers: {
    setAgentInteractionStatistics: (
      state: { agentInteractionStatistics: IStatistics[] },
      action: PayloadAction<IStatistics[]>,
    ) => {
      state.agentInteractionStatistics = action.payload;
    },
    setUserList: (
      state: { userList: IUserList[] },
      action: PayloadAction<IUserList[]>,
    ) => {
      state.userList = action.payload;
    },
    setHoursChart: (
      state: { dataHoursChart: IHoursChart[] },
      action: PayloadAction<IHoursChart[]>,
    ) => {
      state.dataHoursChart = action.payload;
    },
    setSurveyWebChat: (
      state: { surveyWebChat: ISurveyWebChat },
      action: PayloadAction<ISurveyWebChat>,
    ) => {
      state.surveyWebChat = action.payload;
    },
    setUpdateSurveyEpa: (state: { surveyWebChat: ISurveyWebChat },
      action: PayloadAction<ISurveyWebChat>) => {
      state.surveyWebChat.EPA = action.payload.EPA;
    },
    setUpdateSurveyFcr: (state: { surveyWebChat: ISurveyWebChat },
      action: PayloadAction<ISurveyWebChat>) => {
      state.surveyWebChat.FCR = action.payload.FCR;
    },
  },
});

export const {
  setAgentInteractionStatistics,
  setUserList,
  setHoursChart,
  setSurveyWebChat,
  setUpdateSurveyEpa,
  setUpdateSurveyFcr,
} = userStatisticsStore.actions;
export default userStatisticsStore.reducer;
